import React, { useState, useEffect } from 'react'
import Glide from '@glidejs/glide'
import tw from 'twin.macro'
import { ArrowBack } from 'styled-icons/boxicons-regular/ArrowBack'
const Track = tw.div`overflow-x-hidden overflow-y-hidden select-none w-full`

const carouselOptions = {
  type: 'slide',
  perView: 1,
  startAt: 0,
  autoplay: 7000,
  animationTimingFunc: 'linear',
}

export default function Carousel({ children }) {
  useEffect(() => {
    const slider = new Glide(`#glide`, carouselOptions)
    slider.mount()

    return () => {
      slider.destroy()
    }
  }, [])

  return (
    <div css={tw`mx-auto py-12 w-9/12`}>
      <div
        id="glide"
        css={tw`relative rounded-lg block flex items-center bg-gray-f7 shadow-xl w-full`}
      >
        <Track data-glide-el="track">
          <div tw="flex flex-shrink-0">{children}</div>
        </Track>

        <div
          data-glide-el="controls"
          tw="absolute inset-0 flex items-center justify-between"
        >
          <button
            data-glide-dir="<"
            title="left"
            css={tw`block relative bg-white rounded-full shadow-md h-8 w-8 md:h-12 md:w-12 text-2xl text-green hover:text-cherry focus:text-cherry -ml-6 focus:outline-none focus:ring border-0`}
          >
            <span
              css={`
                transform: scale(0.7);
                ${tw`block absolute w-full h-full left-0 top-0`}
              `}
            >
              <ArrowBack tw="text-sm" />
            </span>
          </button>
          <button
            data-glide-dir=">"
            title="right"
            css={tw`block relative bg-white rounded-full shadow-md h-8 w-8 md:h-12 md:w-12 text-2xl text-green hover:text-cherry focus:text-cherry -mr-6 focus:outline-none focus:ring border-0`}
          >
            <span
              css={`
                transform: scale(-0.7);
                ${tw`block absolute w-full h-full left-0 top-0`}
              `}
            >
              <ArrowBack />
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}
