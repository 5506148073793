import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Step03({ bgColor = '#7EBAA9' }) {
  const { t } = useTranslation()

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 540 200"
      style={{ enableBackground: 'new 0 0 540 200' }}
      xmlSpace="preserve"
    >
      <rect x="0.26" y="0.03" fill={bgColor} width="540" height="200" />
      <text
        transform="matrix(1 0 0 1 50 100.2793)"
        fill="#FFFFFF"
        fontFamily="Enriqueta"
        fontWeight="bold"
        fontSize="26.7376px"
      >
        {t('intro.getItem')}
      </text>
      <polyline
        fill={bgColor}
        stroke="#FFFFFF"
        strokeWidth={1.2633}
        strokeLinejoin="round"
        strokeMiterlimit={10}
        points="366.32,95.39 368.64,158.75 461,173.13 498.05,124.83 496.79,68.52 "
      />
      <line
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="1.2633"
        strokeMiterlimit="10"
        x1="458.46"
        y1="104.69"
        x2="461"
        y2="173.13"
      />
      <polyline
        fill="#7EBAA9"
        stroke="#FFFFFF"
        strokeWidth="1.2633"
        strokeMiterlimit="10"
        points="458.46,104.69 475.78,135.35 508.83,94.62 496.79,68.52 "
      />
      <polyline
        fill="#7EBAA9"
        stroke="#FFFFFF"
        strokeWidth="1.2633"
        strokeMiterlimit="10"
        points="458.09,104.51 449.18,129.53 356.77,118.33 366.18,95.26 "
      />
      <polygon
        fill="#589382"
        stroke="#FFFFFF"
        strokeWidth="1.2633"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="413.02,68.77 366.32,95.39 458.46,104.69 496.11,68.77 "
      />
      <line
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="1.2633"
        strokeMiterlimit="10"
        x1="413.02"
        y1="68.77"
        x2="414.64"
        y2="100.26"
      />
      <path
        fill="#FFFFFF"
        d="M436.07,30c-17.92,0-32.45,14.53-32.45,32.45s14.53,32.45,32.45,32.45c17.92,0,32.45-14.53,32.45-32.45
	S453.99,30,436.07,30z M436.07,84.64c-12.26,0-22.19-9.93-22.19-22.19s9.93-22.19,22.19-22.19s22.19,9.93,22.19,22.19
	S448.32,84.64,436.07,84.64z"
      />
      <rect x="434" y="22" fill={bgColor} width="4" height="27" />
    </svg>
  )
}
