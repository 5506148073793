import React from 'react'
import tw, { styled, theme } from 'twin.macro'
import { simpleSurvey } from '../config.json'

import Header from './Header'
import Img from 'gatsby-image'
import useBackend from '../useBackend'
import Carousel from './Carousel'
import { OutLink } from './Link'
import { Link } from 'gatsby-plugin-modal-routing'
import { thingRoute } from '../routes'
import { useTranslation, Trans, useI18next } from 'gatsby-plugin-react-i18next'
import Step00 from './Step00'
import Step01 from './Step01'
import Step02 from './Step02'
import Step03 from './Step03'
import Step04 from './Step04'

const green = theme`colors.green`

const FlexItem = styled.div`
  ${tw`flex-initial p-5`}
  transform: scale(.95);
  :hover {
    transform: scale(1);
    transition: 0.2s;
  }
`

const StyledImg = styled(Img)`
  ${tw`h-full w-full rounded border-solid border-0 cursor-pointer`}
`

function App() {
  const { t } = useTranslation()
  const { language } = useI18next()

  const survey = simpleSurvey[language] ?? simpleSurvey.en

  const { allItems } = useBackend()

  return (
    <div tw="flex justify-start flex-col items-center font-system text-xl">
      <Header tw="self-start" />
      <Carousel>
        <Step00 bgColor={green} />
        <Step01 bgColor={green} />
        <Step02 bgColor={green} />
        <Step03 bgColor={green} />
        <Step04 bgColor={green} />
      </Carousel>

      <p>
        <Trans
          i18nKey="desc.mainSurvey-html"
          components={[
            <OutLink href={survey} rel="noopener noreferrer" target="_blank" />,
          ]}
        >
          Can't find your product? Let us know{' '}
          <OutLink
            href={simpleSurvey.en}
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </OutLink>
          !
        </Trans>
      </p>

      <h2 tw="text-3xl text-cherry mb-5 mt-8">{t('desc.resize')}</h2>
      <div tw="flex justify-center flex-wrap items-center max-w-screen-xl">
        {allItems.map(({ id, label, fixed }) => {
          return (
            <Link key={id} asModal to={thingRoute(id)}>
              <FlexItem>
                <div tw="w-full shadow-2xl p-2 bg-green">
                  <StyledImg fixed={fixed} alt="" />
                </div>
              </FlexItem>
            </Link>
          )
        })}
      </div>
    </div>
  )
}
export default App
