import React from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'
import 'twin.macro'

const Header = ({ ...domProps }) => (
  <div tw="ml-8 md:ml-16 p-3 md:p-8" {...domProps}>
    <div>
      <h1>
        <div tw="text-cherry font-bold font-system text-4xl">CherryKit</div>
      </h1>
      <h2 tw="text-green text-2xl mt-4">
        <Trans i18nKey="site.catchPhrase-html" components={[<b />]}>
          <b>made-to-measure</b> household fixes
        </Trans>
      </h2>
    </div>
  </div>
)

export default Header
