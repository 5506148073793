import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Step04({ bgColor = '#7EBAA9' }) {
  const { t } = useTranslation()

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 540 200"
      style={{ enableBackground: 'new 0 0 540 200' }}
      xmlSpace="preserve"
    >
      <rect x="0.26" y="0.03" fill={bgColor} width="540" height="200" />
      <line
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="1.1335"
        strokeMiterlimit="10"
        x1="327.83"
        y1="13.53"
        x2="533"
        y2="13.53"
      />
      <rect
        x="393.04"
        y="13.53"
        fill="#589382"
        stroke="#FFFFFF"
        strokeWidth="1.1335"
        strokeMiterlimit="10"
        width="48.74"
        height="15.87"
      />
      <rect x="393.04" y="13.53" fill="#FFFFFF" width="48.74" height="7.93" />
      <rect
        x="415.47"
        y="29.4"
        fill="#589382"
        stroke="#FFFFFF"
        strokeWidth="1.1422"
        strokeMiterlimit="10"
        width="4.05"
        height="13.52"
      />
      <circle
        fill="#589382"
        stroke="#FFFFFF"
        strokeWidth="1.3515"
        strokeMiterlimit="10"
        cx="417.5"
        cy="44.27"
        r="2.7"
      />
      <path
        fill="#589382"
        stroke="#FFFFFF"
        strokeWidth="1.2633"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M408.68,39.89L383.8,50.44l-23.29,1.59l15.83,32.89l18.87-16.38l21.63-11.71c2.2-1.06,2.16-5.71-0.09-10.39
	C414.49,41.77,410.88,38.83,408.68,39.89z"
      />
      <ellipse
        transform="matrix(0.9011 -0.4337 0.4337 0.9011 6.7196 166.4328)"
        fill="#7EBAA9"
        stroke="#FFFFFF"
        strokeWidth="1.1335"
        strokeMiterlimit="10"
        cx="368.12"
        cy="68.49"
        rx="5.81"
        ry="18.37"
      />
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="375.2837"
        y1="129.1642"
        x2="-1.2261"
        y2="129.1642"
      >
        <stop offset="0" style={{ stopColor: '#E6E6E6' }} />
        <stop
          offset="0.1437"
          style={{ stopColor: '#EAD5DD', stopOpacity: 0.8563 }}
        />
        <stop
          offset="0.7255"
          style={{ stopColor: '#F994BA', stopOpacity: 0.2745 }}
        />
        <stop offset="1" style={{ stopColor: '#FF7BAC', stopOpacity: 0 }} />
      </linearGradient>
      <polygon
        style={{ opacity: 0.52 }}
        fill="url(#SVGID_1_)"
        points="368.12,58.33 -1.23,200 234.43,200 375.28,76.91 "
      />
      <ellipse
        transform="matrix(0.9011 -0.4337 0.4337 0.9011 7.4546 167.4413)"
        fill="#FFFFFF"
        cx="370.7"
        cy="67.38"
        rx="3.34"
        ry="10.57"
      />
      <g>
        <text
          transform="matrix(1 0 0 1 54.6746 100.2801)"
          fill="#FFFFFF"
          fontFamily="Enriqueta"
          fontWeight="bold"
          fontSize="26.7376px"
        >
          {t('intro.fixed')}
        </text>
      </g>
      <path
        fill="none"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M441.79,18.16c0,0,8.71,1.34,14.71-1.66s6-3,6-3s12,7,18,7s18.99-6.97,18.99-6.97s8.01,6.97,13.01,7.97
	s11,0,11,0s1,0,4-2s3-2,3-2"
      />
    </svg>
  )
}
