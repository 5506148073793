import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Step01({ bgColor = '#7EBAA9' }) {
  const { t } = useTranslation()

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 540 200"
      style={{ enableBackground: 'new 0 0 540 200' }}
      xmlSpace="preserve"
    >
      <rect fill={bgColor} width="540" height="200" />
      <g>
        <text
          fill="#FFFFFF"
          fontFamily="Enriqueta"
          fontWeight="bold"
          fontSize="26.7376px"
          transform="matrix(1 0 0 1 50.7401 108.5006)"
        >
          {t('intro.findPart')}
        </text>
      </g>
      <path
        fill="#FFFFFF"
        d="M454.41,56.18c-25.63,0-46.41,20.78-46.41,46.41S428.78,149,454.41,149c25.63,0,46.41-20.78,46.41-46.41
      S480.04,56.18,454.41,56.18z M454.41,134.33c-17.53,0-31.74-14.21-31.74-31.74s14.21-31.74,31.74-31.74s31.74,14.21,31.74,31.74
      S471.94,134.33,454.41,134.33z"
      />
      <rect x="450" y="51" fill={bgColor} width="8" height="27" />
    </svg>
  )
}
