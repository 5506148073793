import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Step00({ bgColor = '#7EBAA9' }) {
  const { t } = useTranslation()

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 540 200"
      style={{ enableBackground: 'new 0 0 540 200' }}
      xmlSpace="preserve"
    >
      <rect fill={bgColor} width="540" height="200" />
      <path
        fill="none"
        stroke="#FFFFFF"
        strokeWidth={1.1422}
        strokeMiterlimit={10}
        d="M354.5,120.5c-1.07-2.42-4.13-22.69,2-38c2-5,5.29-12.77,20-14c24-2,20.62,6.85,50,11
		c18.45,2.61,30.16-2.17,38-8c9.71-7.23,10.47-21.87,11-30c0.82-12.46-3.52-27.83-8-28c-4.29-0.17-11.26,13.52-7,24
		c2.48,6.1,8.18,9.68,12,11c14.05,4.86,35.04-9.65,53-35"
      />
      <rect
        x="330.15"
        y="110.5"
        fill={bgColor}
        stroke="#FFFFFF"
        strokeWidth="0.9053"
        strokeMiterlimit="10"
        width="48.74"
        height="10.12"
      />
      <g>
        <rect
          x="353.15"
          y="121.07"
          fill={bgColor}
          width="2.91"
          height="12.49"
        />
        <path
          fill="#FFFFFF"
          d="M355.48,121.65v11.34h-1.76v-11.34H355.48 M356.63,120.5h-4.05v13.64h4.05V120.5L356.63,120.5z"
        />
      </g>
      <circle
        fill={bgColor}
        stroke="#FFFFFF"
        strokeWidth="1.3515"
        strokeMiterlimit="10"
        cx="354.6"
        cy="135.49"
        r="2.7"
      />
      <path
        fill={bgColor}
        stroke="#FFFFFF"
        strokeWidth="1.2633"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M343.47,131.83l-21.36,16.56L300,155.89l23.72,27.75l14.05-20.66l17.92-16.85c1.86-1.59,0.63-6.08-2.75-10.02
		C349.57,132.15,345.33,130.24,343.47,131.83z"
      />
      <ellipse
        transform="matrix(0.7602 -0.6497 0.6497 0.7602 -35.6285 243.1778)"
        fill="#497C6C"
        stroke="#FFFFFF"
        strokeWidth="1.2633"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        cx="311.57"
        cy="169.85"
        rx="5.81"
        ry="18.37"
      />
      <ellipse
        transform="matrix(0.7602 -0.6497 0.6497 0.7602 -33.9751 244.1983)"
        fill="#FFFFFF"
        cx="313.78"
        cy="168.12"
        rx="3.34"
        ry="10.57"
      />
      <g>
        <text
          transform="matrix(1 0 0 1 50.7401 108.5006)"
          fill="#FFFFFF"
          fontFamily="Enriqueta"
          fontWeight="bold"
          fontSize="26.7376px"
        >
          {t('intro.hardMount')}
        </text>
      </g>
    </svg>
  )
}
